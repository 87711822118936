import {
  ICommercialType,
  IEquipmentHeight,
  IEquipmentLength,
  IEquipmentType,
  IFuelType,
  IHazmatClass,
  ITruckOptionsItemKeys,
  ITruckOptionsPayload
} from '@truckmap/common';
import { MetaText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { truckOptionsList } from 'components/TruckOptions/TruckOptionsContent';
import { TruckOptionsIcon } from 'components/TruckOptions/TruckOptionsIcon';
import { useCustomKeyDown } from 'hooks/useKeyboard';
import { useTruckOptionValue } from 'hooks/useTruckOptions/useTruckOptionsValue';

import { produce } from 'immer';
import { makeStyles } from 'lib/makeStyles';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';

type TruckSettingsContentProps = {
  payload: ITruckOptionsPayload;
  currentOption: ITruckOptionsItemKeys;
} & ParentClassNameProp;

const useTruckSettingsContentStyles = makeStyles<TruckSettingsContentProps>()({
  root: ['w-full group'],
  icon: ['text-primaryIcon text-xl']
});

export const prettyTruckOptionName = (
  optionItem:
    | IEquipmentHeight
    | IEquipmentLength
    | IEquipmentType
    | ICommercialType
    | IFuelType
    | IHazmatClass
) =>
  (optionItem as IEquipmentHeight)?.display ||
  (optionItem as IHazmatClass)?.shortName ||
  (optionItem as IEquipmentType)?.name ||
  optionItem?.slug;

export const TruckSettingsOption: React.FC<TruckSettingsContentProps> = React.memo((props) => {
  const styles = useTruckSettingsContentStyles(props);
  const { payload, currentOption } = props;
  const { primaryText, name, icon } = payload.truckOptionsLabels[truckOptionsList[currentOption]];
  const setTruckOptionsModal = useSetRecoilState(truckOptionsModalAtom);
  const { value } = useTruckOptionValue(payload, currentOption);

  const handleClick = useCallback(
    (activeOption: ITruckOptionsItemKeys) => () => {
      setTruckOptionsModal(
        produce((draft) => {
          draft.modalOpen = true;
          draft.activeOption = activeOption;
        })
      );
    },
    []
  );

  const handleCustomKeyDown = useCustomKeyDown({
    onEnterOrSpace: handleClick(name)
  });

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.root}
      onClick={handleClick(name)}
      onKeyDown={handleCustomKeyDown}
      key={icon}>
      <PanelItem
        fullWidth
        align="center"
        justify="between"
        rounded="MD"
        intent="primary"
        enableBackground
        enableHover="group"
        paddingX="XS"
        paddingY="XS">
        <PanelFlex spacing="MD" align="start" justify="center">
          <TruckOptionsIcon type={icon} className={styles.icon} />
          <MetaText as="div" thin>
            {primaryText}
          </MetaText>
        </PanelFlex>
        <MetaText as="div">{value}</MetaText>
      </PanelItem>
    </div>
  );
});

TruckSettingsOption.displayName = 'TruckSettingsOption';
