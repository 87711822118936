import { ITruckOptionsItemKeys, ITruckOptionsPayload, UUID } from '@truckmap/common';
import { prettyTruckOptionName } from 'components/RoutePlanner/RouteSettings/TruckSettingsOption';
import { truckOptionsList } from 'components/TruckOptions/TruckOptionsContent';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userMetasAtom } from 'recoil/user';

export const useTruckOptionValue = (
  payload: ITruckOptionsPayload,
  currentOption: ITruckOptionsItemKeys
) => {
  if (!payload || !currentOption) return;
  const userMetas = useRecoilValue(userMetasAtom);

  const value = useMemo(() => {
    const currentOptionList = payload.truckOptionsList[currentOption];
    const currentOptionId = truckOptionsList[currentOption];

    const id: UUID =
      userMetas[currentOptionId] ||
      currentOptionList.find(({ default: isDefault }) => isDefault)?.id;

    const optionItem = currentOptionList.find(({ id: currentId }) => currentId === id);
    const value = prettyTruckOptionName(optionItem);

    return value;
  }, [payload, userMetas]);

  return { value };
};
