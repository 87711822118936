import { ITruckOptionsItem } from '@truckmap/common';
import { RadioGroup } from 'components/common/Input/SelectInputGroup';
import { InputOptionType } from 'components/common/Input/SelectInputGroup/RadioGroup';
import { prettyTruckOptionName } from 'components/RoutePlanner/RouteSettings/TruckSettingsOption';
import { makeStyles } from 'lib/makeStyles';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userMetasAtom } from 'recoil/user/userMetasAtom';

export type TruckOptionsItemComponentProps = {
  item: ITruckOptionsItem;
  name: string;
} & ParentClassNameProp;

const useTruckOptionsItemComponentStyles = makeStyles<TruckOptionsItemComponentProps>()({
  root: (props) => [props.className],
  radioGroup: ['overflow-y-auto', 'sm:max-h-[470px]'],
  radioGroupOption: 'py-[0.19rem]'
});

export const TruckOptionsItemComponent: React.FC<TruckOptionsItemComponentProps> = React.memo(
  (props) => {
    const { item, name: radioGroupName } = props;
    const styles = useTruckOptionsItemComponentStyles(props);
    const userMetas = useRecoilValue(userMetasAtom);

    const checkedId: string = useMemo(
      () => userMetas[props.name] || item.find((option) => option.default)?.id,
      [item]
    );

    const radioOptions: InputOptionType[] = useMemo(
      () =>
        item.map((option) => ({
          label: prettyTruckOptionName(option),
          value: option.id,
          defaultChecked: option.id === checkedId
        })),
      [item]
    );

    return (
      <div className={styles.root}>
        <RadioGroup
          className={styles.radioGroup}
          optionClassName={styles.radioGroupOption}
          name={radioGroupName}
          options={radioOptions}
          hasSelectedSticky
        />
      </div>
    );
  }
);

TruckOptionsItemComponent.displayName = 'TruckOptionsItemComponent';
