import { ITruckOptionsItemKeys, ITruckOptionsLabel, ITruckOptionsPayload } from '@truckmap/common';
import { Button } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { MetaText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { truckOptionsListIds } from 'components/TruckOptions/TruckOptionsContent';
import { TruckOptionsIcon } from 'components/TruckOptions/TruckOptionsIcon';
import { useTruckOptionValue } from 'hooks/useTruckOptions/useTruckOptionsValue';

import { produce } from 'immer';
import { makeStyles } from 'lib/makeStyles';
import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { useRecoilCallback } from 'recoil';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';

type TruckOptionsNavProps = ParentClassNameProp & {
  payload: ITruckOptionsPayload;
  activeOption: ITruckOptionsItemKeys;
};

type TruckOptionsNavPropsItem = {
  labelName: ITruckOptionsItemKeys;
  label: ITruckOptionsLabel;
  labelKey?: string;
} & TruckOptionsNavProps;

const useTruckOptionsNavStyles = makeStyles<TruckOptionsNavProps>()({
  root: (props) => [props.className]
});

const useTruckOptionsNavItemStyles = makeStyles<
  Pick<TruckOptionsNavPropsItem, 'labelName' | 'activeOption'> & { isActive?: boolean }
>()({
  navButton: ({ activeOption, labelName }) => [
    'group',
    'md:px-2 py-[0.19rem]',
    'border-b border-neutralBorder',
    {
      'bg-primaryBackgroundActive': activeOption === labelName,
      'hover:bg-primaryBackgroundActive': activeOption !== labelName
    }
  ],
  icon: ['text-primaryIcon text-xl', 'shrink-0', 'hidden sm:block'],
  arrow: (props) => [
    'w-3 h-3',
    'w-3 h-3',
    {
      'bg-secondaryIcon group-hover:bg-primaryIcon': !props.isActive,
      'bg-primaryIcon': props.isActive,
      'transition-opacity md:transition-color duration-200': !props.isActive,
      'opacity-0 md:opacity-100 group-hover:opacity-100': !props.isActive
    }
  ],
  arrowIconWrapper: (props) => [
    'h-3 md:h-3 overflow-hidden',
    {
      'transition-all duration-200 ease-in-out': !props.isActive,
      'w-0 group-hover:w-4': !props.isActive,
      'md:transition-none': !props.isActive,
      'md:w-3 md:group-hover:w-3': !props.isActive,
      'w-3': props.isActive
    }
  ],
  valueLabel: ['sm:hidden']
});

const TruckOptionsNavItem: React.FC<TruckOptionsNavPropsItem> = React.memo(
  ({ labelName, label, activeOption, payload, labelKey }) => {
    const isActive = activeOption === labelName;
    const styles = useTruckOptionsNavItemStyles({ labelName, activeOption, isActive });
    const buttonRef = useRef<HTMLButtonElement>(null);
    const labelOption = truckOptionsListIds[labelKey];

    const { value } = useTruckOptionValue(payload, labelOption as ITruckOptionsItemKeys);

    useEffect(() => {
      if (activeOption === labelName && buttonRef?.current) {
        buttonRef.current.focus();
      }
    }, [buttonRef, activeOption, labelName]);

    const setItem = useRecoilCallback(
      ({ set }) =>
        (e: SyntheticEvent) => {
          e.preventDefault();
          set(
            truckOptionsModalAtom,
            produce((draft) => {
              draft.activeOption = labelName;
              draft.activeModalTitle = label.primaryText;
            })
          );
        },
      [label]
    );

    return (
      <Button
        ref={buttonRef}
        key={labelName}
        fullWidth
        customClassName={styles.navButton}
        spacing={false}
        borderless
        onClick={setItem}>
        <PanelFlex fullWidth as="span" paddingY="XS" justify="between">
          <PanelFlex row padding={false} as="span">
            <TruckOptionsIcon type={label.icon} className={styles.icon} />
            <MetaText bold={isActive} active={isActive}>
              {label.primaryText}
            </MetaText>
          </PanelFlex>

          <PanelFlex row>
            <MetaText className={styles.valueLabel}>{value}</MetaText>
            <div className={styles.arrowIconWrapper}>
              <HeroIcon type="solid" name="chevron-right" className={styles.arrow} />
            </div>
          </PanelFlex>
        </PanelFlex>
      </Button>
    );
  }
);

TruckOptionsNavItem.displayName = 'TruckOptionsNavItem';

export const TruckOptionsNav: React.FC<TruckOptionsNavProps> = React.memo((props) => {
  const styles = useTruckOptionsNavStyles(props);
  const labelKeys = useMemo(() => Object.keys(props.payload.truckOptionsLabels), [props.payload]);

  if (!styles || !labelKeys) return <></>;

  return (
    <PanelFlex spacing={false} column justify="start" align="start" className={styles.root}>
      {labelKeys.map((label) => (
        <TruckOptionsNavItem
          key={label}
          activeOption={props.activeOption}
          labelName={label as ITruckOptionsItemKeys}
          label={props.payload.truckOptionsLabels[label] as ITruckOptionsLabel}
          labelKey={label}
          payload={props.payload}
        />
      ))}
    </PanelFlex>
  );
});

TruckOptionsNav.displayName = 'TruckOptionsNav';
