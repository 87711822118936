import { GlyphProps } from 'components/common/Glyph';
import { AvoidTollwaysIcon } from 'icons/monochrome/AvoidTollwaysIcon';
import { CommercialIcon } from 'icons/monochrome/CommercialIcon';
import { FuelTypeIcon } from 'icons/monochrome/FuelTypeIcon';
import { HazmatIcon } from 'icons/monochrome/HazmatIcon';
import { TrailerLengthIcon } from 'icons/monochrome/TrailerLengthIcon';
import { TrailerTypeIcon } from 'icons/monochrome/TrailerTypeIcon';
import { TruckHeightIcon } from 'icons/monochrome/TruckHeightIcon';
import { TruckIcon } from 'icons/monochrome/TruckIcon';
import { TruckWeightIcon } from 'icons/monochrome/TruckWeightIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export const TruckOptionsIconsMap = new Map<
  string,
  React.MemoExoticComponent<(props: GlyphProps) => JSX.Element>
>([
  ['truck_height', TruckHeightIcon],
  ['truck_weight', TruckWeightIcon],
  ['trailer_type', TrailerTypeIcon],
  ['trailer_length', TrailerLengthIcon],
  ['hazmat', HazmatIcon],
  ['fuel_type', FuelTypeIcon],
  ['commercial', CommercialIcon],
  ['avoid_tollways', AvoidTollwaysIcon]
]);

export type TruckOptionsIconProps = { type: string } & ParentClassNameProp;

const useTruckOptionsIconStyles = makeStyles<TruckOptionsIconProps>()({
  root: (props) => [props.className]
});

export const TruckOptionsIcon: React.ComponentType<TruckOptionsIconProps> = React.memo((props) => {
  const IconComponent = TruckOptionsIconsMap.get(props.type) ?? TruckIcon;
  const styles = useTruckOptionsIconStyles(props);

  return <IconComponent className={styles.root} />;
});

TruckOptionsIcon.displayName = 'TruckOptionsIcon';
