import { SelectItemLabel } from 'components/common/SelectItem/SelectItemLabel';
import { PanelItem, PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles, VariantStyleProps } from 'lib/makeStyles';
import React, { memo } from 'react';

export type SelectItemStyleProps = {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  intent?: 'primary' | 'secondary';
  customTextColor?: string;
} & VariantStyleProps;

export type SelectItemProps = {
  icon?: React.ReactNode;
} & Omit<SelectItemStyleProps, 'variantStyles'> &
  Pick<PanelItemProps, 'paddingY' | 'enableBackground' | 'reverse'> &
  ChildrenProp &
  ParentClassNameProp;

export const useStyles = makeStyles<SelectItemStyleProps>()({
  root: (props) => [
    'h-full w-full',
    {
      'cursor-pointer': !props?.disabled
    },
    props?.className
  ]
});

export const SelectItem: React.FC<SelectItemProps> = memo(
  ({
    children,
    checked = false,
    disabled = false,
    icon,
    className,
    paddingY = 'MD',
    enableBackground = false,
    reverse = false,
    intent = 'primary'
  }: SelectItemProps) => {
    const styles = useStyles({
      className
    });

    return (
      <div className={styles.root}>
        <PanelItem
          reverse={reverse}
          align="center"
          justify="start"
          paddingY={paddingY}
          enableBackground={enableBackground}
          intent={intent}
          enableHover
          fullWidth>
          {icon && icon}
          <SelectItemLabel checked={checked} disabled={disabled}>
            {children}
          </SelectItemLabel>
        </PanelItem>
      </div>
    );
  }
);

SelectItem.displayName = 'SelectItem';
