import { SelectItem, SelectItemProps } from 'components/common/SelectItem';
import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles, VariantStyleProps } from 'lib/makeStyles';
import React, { useCallback } from 'react';
import { useGridOverlapStyles } from 'styles';

import { Radio } from './Radio';

export type SelectInputStyleProps = {
  hideInput?: boolean;
} & SelectItemProps &
  VariantStyleProps &
  ParentClassNameProp;

export type SelectInputProps = Omit<SelectInputStyleProps, 'variantStyles'> &
  Pick<PanelItemProps, 'paddingY'> &
  ChildrenProp;

export type RadioInputProps = SelectInputProps &
  JSX.IntrinsicElements['input'] &
  Required<Pick<JSX.IntrinsicElements['input'], 'name' | 'value'>>;

export const useStyles = makeStyles<SelectInputStyleProps>()({
  root: ({ className }) => [
    'flex flex-row',
    'gap-x-2 justify-start items-center',
    'w-full h-full',
    'group',
    'rounded',
    'focus-within:ring-2 focus-within:ring-tmBlue',
    className
  ],
  inputHidden: ['flex ml-2 items-center'],
  ghostInput: ({ hideInput }) => ['ml-2 h-[1px] w-[1px]', { 'sr-only': hideInput }],
  // margin vertically centers label and input
  gridMargin: ({ hideInput }) => [{ 'mt-1': !hideInput }]
});

export const RadioInput: React.FC<RadioInputProps> = ({
  children,
  id,
  name,
  checked = false,
  disabled = false,
  icon,
  hideInput = false,
  paddingY = 'MD',
  className,
  intent,
  ...inputProps
}: RadioInputProps) => {
  const styles = useStyles({
    disabled,
    className,
    hideInput,
    paddingY
  });

  const gridStyles = useGridOverlapStyles({ className: styles.gridMargin });
  const fieldId = `radio_${id ?? name}`;

  const renderContent = useCallback(
    () => (
      <SelectItem
        paddingY={paddingY}
        className={hideInput ? gridStyles.gridItem : undefined}
        checked={checked}
        disabled={disabled}
        intent={intent}
        icon={icon}>
        {children}
      </SelectItem>
    ),
    [icon, children, paddingY, checked, disabled, intent]
  );

  return (
    <label htmlFor={id} className={styles.root}>
      <div className={gridStyles.grid}>
        <div className={gridStyles.gridItem}>
          <input
            type="radio"
            id={fieldId}
            key={`radio_${fieldId}`}
            tabIndex={0}
            disabled={disabled}
            className={styles.ghostInput}
            name={name}
            // remove checked when defaultChecked without onChange handler to avoid console error
            checked={inputProps.defaultChecked === undefined ? checked : undefined}
            {...inputProps}
          />
        </div>
        {!hideInput ? (
          <div aria-hidden={true} className={gridStyles.gridItem}>
            <Radio type="radio" checked={checked} disabled={disabled} />
          </div>
        ) : (
          renderContent()
        )}
      </div>
      {!hideInput && renderContent()}
    </label>
  );
};

RadioInput.displayName = 'RadioInput';
